<template>
  <main>
    <ServicesCover />
    <ServicesList />
    <Footer />
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import ServicesCover from "@/components/Services/ServicesCover";
import ServicesList from "@/components/Services/ServicesList";
import Footer from "@/components/Common/Footer";
export default {
  name: "Services",
  components: { Footer, ServicesList, ServicesCover }
};
</script>
