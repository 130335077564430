<template>
  <section class="wrapper-y lean-wrap">
    <div class="service flex mb-xl" v-for="(service, i) in services" :key="i">
      <div class="service-image-area half-width flex mb-lg">
        <div class="img-box" style="">
          <img :src="getImgUrl(service.img)" alt="" />
        </div>
      </div>
      <div class="service-info half-width py-lg">
        <div class="service-title bold text-primary">{{ service.title }}</div>
        <p class="service-description">{{ service.description }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ServicesList",
  data() {
    return {
      services: [
        {
          img: "webApplicationDevelopment",
          title: this.$t("Services.WebAppDevelopment"),
          description: this.$t("Services.WebAppDevelopmentDescription")
        },
        {
          img: "mobileApplicationDevelopment",
          title: this.$t("Services.MobileAppDevelopment"),
          description: this.$t("Services.MobileAppDevelopmentDescription")
        },
        {
          img: "dataAnalysis",
          title: this.$t("Services.DataAnalytics"),
          description: this.$t("Services.DataAnalyticsDescription")
        },
        {
          img: "consulting",
          title: this.$t("Services.Consulting"),
          description: this.$t("Services.ConsultingDescription")
        },
        {
          img: "cloudComputing",
          title: this.$t("Services.CloudComputing"),
          description: this.$t("Services.CloudComputingDescription")
        }
      ]
    };
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/images/" + pic + ".jpg");
    }
  }
};
</script>
<style lang="scss">
.lean-wrap {
  padding: 10vh 15vw;
}
.service-title {
  font-family: "Poppins";
  font-size: 26px;
  color: #333;
}

.service-description {
  font-size: 18px;
  color: #7c7c7c;
  font-weight: 400;
  line-height: 2rem !important;
}
.service {
  p {
    line-height: 1.5rem;
  }
  .img-box {
    height: 300px;
    width: 420px;
    position: relative;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 10px;
    &::before {
      content: "";
      height: 95%;
      width: 95%;
      position: absolute;
      border: 3px solid #BDD8A4;
      border-radius: 8px;
      top: 16px;
      left: -16px;
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 8px;
      object-position: center;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
  }
  &:nth-child(odd) {
    flex-direction: row-reverse;
    .service-image-area {
      justify-content: flex-end;
    }
    .img-box {
      &::before {
        right: -16px;
        left: unset;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .lean-wrap {
    padding-bottom: 0;
  }
  .service {
    margin-bottom: 24px;
    flex-direction: column;
    .service-image-area,
    .service-info {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .img-box {
        width: 100%;
      }
    }
    &:nth-child(n) {
      flex-direction: column;
    }
  }
}
</style>
